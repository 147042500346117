export const FONT_LIST = [
  {
    name: 'SUIT',
    value: 'SUIT',
  },
  {
    name: '어그로체',
    value: 'agro',
  },
  {
    name: '산돌 삼립호빵체 Basic',
    value: 'samlip',
  },
];

export const FONT_LIST_TO_TAILWIND = ['font-suit', 'font-agro', 'font-samlip'];
